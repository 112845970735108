import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EmailingService } from '../service/emailing.service';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {

  private _subscribed: Array<Subscription> = [];
  private _company: string = null;
  private _company_key: number = null;

  constructor(
    private _emailingServ: EmailingService,
    private _datePipe: DatePipe
  ) {
    // CANNOT HANDLE COMPANY SERV BECAUSE OF CIRCULAR DEPENDENCIES
    // private _companyServ: CompanyService,
    // this._subscribed.push(
    //   this._companyServ.getCompanyFullObservable().subscribe(
    //     company => {
    //       if (company) {
    //         this._company = company;
    //       }
    //     }
    //   )
    // );
    // reset counter each 15 minutes
    let minutes_interval_ms = 15 * 60000;
    window.setInterval(
      () => {
        this._eventsCounter = 0;
        this._urgentEventsCounter = 0;
      }, minutes_interval_ms
    );
  }
  
  // max errors logs count (no spams for the same error produced many times at the same time)
  private readonly _MAX_TIME_TAKEN: number = 7500;
  private readonly _MAX_EVENTS_LOG: number = 2;
  private _eventsCounter: number = 0;

  private readonly _MAX_URGENT_TIME_TAKEN: number = 15000;
  private readonly _MAX_URGENT_EVENTS_LOG: number = 5;
  private _urgentEventsCounter: number = 0;

  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = performance.now();
    return next.handle(req).pipe(
      tap(event => {
        if (event.type === HttpEventType.Response) { // 4 HttpResponse event
          // console.log(req);
          // console.log(event);

          // save company ids from response
          if (event.body && event.body.company && !this._company) {
            this._company = event.body.company;
          } 
          if (event.body && event.body.company_key && !this._company_key) {
            this._company_key = event.body.company_key;
          } 

          const endTime = performance.now();
          const timeTaken = endTime - startTime; 
          // Approximate size in bytes
          const responseSize = (new TextEncoder().encode(JSON.stringify(event.body))).length;
          const sizeMB = responseSize / (1024 * 1024);

          // console.log(`Request URL: ${req.url}`);
          // console.log(`Time Taken: ${timeTaken.toFixed(2)}ms`);
          // console.log(`Response Size: ${sizeMB.toFixed(2)} MB`);
          
          const excluded_urls = [
            'api.openai.com', 'combine=attachments', 'combine=all',
            '/health'
          ];
          if (event.url && event.url.includes('nettedev/api/') && !excluded_urls.some(excluded => event.url.includes(excluded))) {
            let send_email_log: boolean = false;
            
            if (timeTaken > this._MAX_URGENT_TIME_TAKEN) {
              // filter requests that are performed very long
              this._urgentEventsCounter += 1;
              if (this._urgentEventsCounter <= this._MAX_URGENT_EVENTS_LOG) {
                send_email_log = true;
              }
            }
            else if (timeTaken > this._MAX_TIME_TAKEN) {
              // filter requests that are performed long
              this._eventsCounter += 1;
              if (this._eventsCounter <= this._MAX_EVENTS_LOG) {
                send_email_log = true;
              }
            }

            // send email according to flag
            if (send_email_log) {
              let error_report: string = '';
                error_report += this._datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm:ss') + '\n';
                if (this._company && this._company_key) {
                  error_report += this._company + ' (company_key: ' + this._company_key + ')\n';
                }
                error_report += 'URL stránky/komponenty:\n' + window.location.href + '\n\n';
                error_report += 'Request URL:\n' + event.url + '\n\n';
                error_report += 'Response status: ' + event.status + ' ('+ event.statusText + ')\n\n';
                error_report += 'Angular čas (doba od zahájení requestu po dobu zpracování response):\n';
                error_report += timeTaken.toFixed(2) + 'ms\n\n';
                error_report += 'Velikost response:\n' + '~' + sizeMB.toFixed(2) + 'MB\n\n';
                

                let emailObj: any = {
                  from: 'info@truckmanager.eu',
                  to: ['ambroz.vita@gmail.com', 'jan.zimmermann80@gmail.com'],
                  reply_to: 'info@truckmanager.eu',
                  subject: 'Angular interceptor - vyšší čas načítání',
                  body: error_report,
                  content_type: 'text/plain'
                };

                this._subscribed.push(
                  this._emailingServ.sendEmail(emailObj, false).subscribe()
                );
            }
          }
        }
      })
    );
  }
}