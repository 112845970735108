import { DateTools } from "../tools/DateTools";

export class Attachment {
  // unique ID of the attachment within obligation
  private _id: number;
  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  // original name when the file was uploaded (file name on user’s computer)
  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }

  // e.g. "/files/company/obligation-42491-a?name=a" URL to download the file
  private _url: string;
  public get url(): string {
    return this._url;
  }
  public set url(value: string) {
    this._url = value;
    // set also normalized url without query 
    if (this._url) {
      if (this._url.includes('?name')) {
        this._url_normalized = this._url.substring(0, this._url.indexOf('?name'));
      }
      else {
        this._url_normalized = this._url;
      }
    }
  }

  // normalized url without query
  private _url_normalized: string;
  public get url_normalized(): string {
    return this._url_normalized;
  }
  public set url_normalized(value: string) {
    this._url_normalized = value;
  }

  // file size
  private _size: number;
  public get size(): number {
    return this._size;
  }
  public set size(value: number) {
    this._size = value;
    if (this._size) {
      this._sizeMB = this._size / 1000000;
    }
  }

  get sizeKB(): number {
    if (this._size) {
      return Math.floor(this._size / 1000);
    }
    return 0;
  }

  // custom
  private _sizeMB: number = 0;
  public get sizeMB(): number {
    return this._sizeMB;
  }
  public set sizeMB(value: number) {
    this._sizeMB = value;
  }

  private _type: string;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  // date of modification as string
  private _modified: string;
  public get modified(): string {
    return this._modified;
  }
  public set modified(value: string) {
    this._modified = value;
  }

  get modifiedDate(): Date {
    if (this._modified) {
      return DateTools.isoFix(this._modified);
    }
    return null;
  }

  private _origin: string;
  public get origin(): string {
    return this._origin;
  }
  public set origin(value: string) {
    this._origin = value;
  }

  private _fileContent: any = null;
  public get fileContent(): any {
    return this._fileContent;
  }
  public set fileContent(value: any) {
    this._fileContent = value;
  }

  // custom
  private _thumbnail: any;
  public get thumbnail(): any {
    return this._thumbnail;
  }
  public set thumbnail(value: any) {
    this._thumbnail = value;
  }

  // custom
  private _thumbnailBase64: string;
  public get thumbnailBase64(): string {
    return this._thumbnailBase64;
  }
  public set thumbnailBase64(value: string) {
    this._thumbnailBase64 = value;
  }

  // custom flag 
  private _loadingThumbnail: boolean = false;
  public get loadingThumbnail(): boolean {
    return this._loadingThumbnail;
  }
  public set loadingThumbnail(value: boolean) {
    this._loadingThumbnail = value;
  }
  

  // custom flag - true if attachment is attached to invoicing email
  private _isAttached: boolean = true;
  public get isAttached(): boolean {
    return this._isAttached;
  }
  public set isAttached(value: boolean) {
    this._isAttached = value;
  }

  // custom flag
  private _downloadingAttachment: boolean = false;
  public get downloadingAttachment(): boolean {
    return this._downloadingAttachment;
  }
  public set downloadingAttachment(value: boolean) {
    this._downloadingAttachment = value;
  }
}