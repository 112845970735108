import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { IS_DEMO, ServiceConfiguration } from "../config";
import { DateTools } from "../tools/DateTools";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { Order } from "../model/order.object";
import { OrderTools } from "../tools/OrderTools";
import { Person } from '../model/person.object';
import { Obligation } from '../model/obligation.object';
import { Invoice } from '../model/invoice.object';
import { Email } from '../model/email.object';
import { InvoiceTools } from '../tools/InvoiceTools';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private _orders: BehaviorSubject<Array<Order>> = new BehaviorSubject([]);
  private _ordersCache: Array<Order> = [];
  private _loadingOrders: boolean = false;
  get loadingOrders(): boolean {
    return this._loadingOrders;
  }
  private _loadingNextPage: boolean = false;
  get loadingNextPage(): boolean {
    return this._loadingNextPage;
  }

  private _orderNumbers: BehaviorSubject<Array<Order>> = new BehaviorSubject([]);
  private _orderNumbersCache: Array<Order> = [];
  private _loadingOrderNumbers: boolean = false;

  private _order: BehaviorSubject<Order> = new BehaviorSubject(null);
  private _orderCache: Order = null;
  private _loadingOrder: boolean = false;
  public get loadingOrder(): boolean {
    return this._loadingOrder;
  }

  private _filters: any = {};
  private _sorts: any = {};
  private _page: number = 0;
  private _size: number = 0;
  
  private _filterNames: Array<string> = [
    'order',
    'tf',
    'tt',
    'year',
    'series',
    'status',
    'company',
    'company_key',
    'person_key',
    'updated',
    'favourite',
    'received_invoice'
  ];

  private _sortNames: Array<string> = [
    'number',
    'series',
    'status',
    'company',
    'created'
  ];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  public clearOrdersCache(): void {
    this._ordersCache = [];
  }

  public clearOrderCache(): void {
    this._orderCache = null;
  }
  
  public ordersFilteredTotalRecords: number = 0;
  public ordersFilteredTotalPages: number = 0;

  // method for getting obligations with given filter object
  getOrdersFiltered(filterObj: any, sortObj: any, page: number = 0, size: number = 50): Observable<Array<Order>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // init attributtes
      this._filters = filterObj;
      this._sorts = sortObj;
      this._page = page;
      this._size = size;
      // default url without any filter
      let url: string = ServiceConfiguration.orders.api + '?page=' + this._page + '&size=' + this._size;

      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);
        
      // console.log(url);
      // set loading flags
      this._loadingOrders = true;
      // if (this._page > 0) {
      //   this._loadingNextPage = true;
      // }

      // handling full response (with headers) not just body as usual
      let httpOptions = {
        observe: 'response' as 'body'
      };

      this._http.get(url, httpOptions).subscribe(
        response => {
          if (response && response.body) {
            if (response.headers) {
              if (response.headers.get('X-TM-API-Total-Records')) {
                this.ordersFilteredTotalRecords = response.headers.get('X-TM-API-Total-Records');
              }
              if (response.headers.get('X-TM-API-Total-Pages')) {
                this.ordersFilteredTotalPages = response.headers.get('X-TM-API-Total-Pages');
              }
            } 
            this._ordersCache = OrderTools.buildOrdersFromData(response.body);
            this._orders.next(this._ordersCache);
          }
        },
        error => {
          // handle error
          console.log(error);
          this._ordersCache = [];
          this._orders.next(this._ordersCache);
          this._loadingOrders = false;
          this._loadingNextPage = false;
        },
        () => {
          this._loadingOrders = false;
          this._loadingNextPage = false;
        }
      );
    }

    return this._orders.asObservable();
  }


  // method for getting orders with given filter object
  // same as above but for further processing in component
  getOrdersFilteredRequestToComponent(filterObj: any, sortObj: any, page: number = 0, size: number = 50): Observable<Array<any>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.orders.api + '?page=' + page + '&size=' + size;
      
      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);

      return this._http.get(url);
    }
  }

  // method for creating url string for filtering
  filterUrl(filterObj: any) {
    let result = '';
    let filterKeys: Array<string> = Object.keys(filterObj);
    filterKeys.forEach(
      key => {
        // check possible filters for obligations
        if (this._filterNames.includes(key)) {
          result += '&' + key + '=' + filterObj[key];
        }
      }
    );
    return result;
  }
  
  // method for creating url string for sorting
  sortUrl(sortObj: any) {
    let result = '';
    let sortKeys: Array<string> = Object.keys(sortObj);
    sortKeys.forEach(
      key => {
        // check possible sorts for obligations (should be always only one sort param)
        if (this._sortNames.includes(key)) {
          result += '&sort=' + key + ',' + sortObj[key];
        }
      }
    );
    return result;
  }


  // method for getting order with given partly matched string of order_number
  getOrderWithOrderNumber(order_number: string): Observable<Array<Order>> {
    this._loadingOrderNumbers = true;
    if (order_number && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.get(ServiceConfiguration.orders.api + '?order=' + order_number).subscribe(
        response => {
          this._orderNumbersCache = OrderTools.buildOrdersFromData(response);
          // slice number of orders to max constant value 5
          if (this._orderNumbersCache.length > 5) {
            this._orderNumbersCache = this._orderNumbersCache.slice(0, 5);
          }
          this._orderNumbers.next(this._orderNumbersCache);
        },
        error => {
          // handle error
          this._orderNumbersCache = [];
          this._orderNumbers.next(this._orderNumbersCache);
          console.log(error);
        },
        () => {
          this._loadingOrderNumbers = false;
        }
      );
    }

    return this._orderNumbers.asObservable();
  }
  
  // method for getting order with given partly matched string of order_number
  getOrderWithOrderNumberRequest(order_number: string): Observable<Array<Order>> {
    let result: BehaviorSubject<Array<Order>> = new BehaviorSubject([]);

    this._loadingOrderNumbers = true;
    if (order_number && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.orders.api + '?order=' + order_number;
      url += '&page=0&size=5'; // &mode=light';   

      this._http.get(url).subscribe(
        response => {
          let orders = OrderTools.buildOrdersFromData(response);
          // slice number of orders to max constant value 5
          if (orders.length > 5) {
            orders = orders.slice(0, 5);
          }
          result.next(orders);
          this._loadingOrderNumbers = false;
        },
        error => {
          // handle error
          result.next([]);
          console.log(error);
          this._loadingOrderNumbers = false;
        }
      );
    }

    return result.asObservable();
  }

  // method for getting obligation with given key
  getOrder(order_key: number): Observable<Order> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderKey.replace('%ORDER_KEY%', order_key.toString());

      this._loadingOrder = true;

      this._http.get(url).subscribe(
        response => {
          if (response) {
            this._orderCache = OrderTools.buildOrder(response);
            this._order.next(this._orderCache);
          }
        },
        error => {
          // handle error
          console.log(error);
          this._loadingOrder = false;
        },
        () => {
          // finally
          this._loadingOrder = false;
        }
      );
    }

    return this._order.asObservable();
  }

  // method for getting order with given key
  getOrderToComponent(order_key: number): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderKey.replace('%ORDER_KEY%', order_key.toString());
      return this._http.get(url);
    }
  }

  
  // method for updating order properties
  updateOrder(order: Order): Observable<Order> {
    let updateOrder: BehaviorSubject<Order> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // just for sure - normalize ngbTypeahed inputs/properties
      if (order.order_contact instanceof Person) order.order_contact = order.order_contact.name;
      if (order.order_email instanceof Person) order.order_email = order.order_email.email;
      if (order.order_phone instanceof Person) {
        order.order_phone = order.order_phone.mobile ? order.order_phone.mobile : order.order_phone.phone;
      }

      // initialize data
      let data = order.apiObject;

      // we support also updating of created time property
      if (order.created_time_input) {
        data['created_time'] = DateTools.toIsoWithoutMilisec(new Date(order.created_time_input));
      }

      let url: string = ServiceConfiguration.orders.apiOrderKey.replace('%ORDER_KEY%', order.order_key.toString());
      // set also flag for uploading
      this._loadingOrder = true;

      this._http.put(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Parametry objednávky %ORDER% byly úspěšně upraveny.`;
          alertSuccess = alertSuccess.replace('%ORDER%', order.orderNumberFormatted);
          this._notificationService.alert(alertSuccess, 'success', 4000);
          
          // observable next
          updateOrder.next(OrderTools.buildOrder(response));
          this._loadingOrder = false;
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě parametrů objednávky %ORDER%.`;
          alertError = alertError.replace('%ORDER%', order.orderNumberFormatted);
          this._notificationService.alert(alertError, 'error', 4000);
          this._loadingOrder = false;
        }
      );
    }

    return updateOrder.asObservable();
  }


  // method for creating new order
  createOrder(order: Order): Observable<Order> {
    let createOrder: BehaviorSubject<Order> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // just for sure - normalize ngbTypeahed inputs/properties
      if (order.order_contact instanceof Person) order.order_contact = order.order_contact.name;
      if (order.order_email instanceof Person) order.order_email = order.order_email.email;
      if (order.order_phone instanceof Person) {
        order.order_phone = order.order_phone.mobile ? order.order_phone.mobile : order.order_phone.phone;
      }

      // initialize data
      let data = order.apiObject;

      // we support also updating of created time property
      if (order.created_time_input) {
        data['created_time'] = DateTools.toIsoWithoutMilisec(new Date(order.created_time_input));
      }

      let url: string = ServiceConfiguration.orders.api;
      // set also flag for uploading
      this._loadingOrder = true;

      this._http.post(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Objednávka %ORDER% byla úspěšně vytvořena.`;
          alertSuccess = alertSuccess.replace('%ORDER%', response.order_number_standard);
          this._notificationService.alert(alertSuccess, 'success', 4000);
          
          // observable next
          createOrder.next(OrderTools.buildOrder(response));
          this._loadingOrder = false;
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při vytváření objednávky.`;
          this._notificationService.alert(alertError, 'error', 4000);
          this._loadingOrder = false;
        }
      );
    }

    return createOrder.asObservable();
  }


  // method for deleting order
  deleteOrder(order: Order): void {
    if (order && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.orders.apiOrderKey.replace('%ORDER_KEY%', order.order_key.toString());
      // delete request
      this._http.delete(url).subscribe(
        response => {
          // OK <=> 204 no content
          let alertSuccess: string = $localize`Objednávka %ORDER% byla úspěšně odstraněna.`;
          alertSuccess = alertSuccess.replace('%ORDER%', order.orderNumberFormatted);
          this._notificationService.alert(alertSuccess, 'success', 4000);
          
          // new get request for current filters of orders and subscribe
          this.getOrdersFiltered(this._filters, this._sorts, this._page, this._size).subscribe();
        },
        error => {
          // handle error
          console.log(error);
          let alertError: string = $localize`Chyba při mazání objednávky %ORDER%.`;
          alertError = alertError.replace('%ORDER%', order.orderNumberFormatted);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
  }

  // method for creating association between order and obligation
  createAssociationOrderWithObligation(order: Order, obligation: Obligation): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderObligation;
      url = url.replace('%ORDER_KEY%', order.order_key.toString());

      let data: any = { order_key: order.order_key, obligation_key: obligation.obligation_key };
      // post
      this._http.post(url, data).subscribe(
        response => {
          // console.log(response);
          result.next({OK: true});
        },
        error => {
          result.next({OK: false});
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při vytváření vazby mezi objednávkou %ORDER% a zakázkou %OBLIG%.`;
          alertError = alertError.replace('%ORDER%', order.orderNumberFormatted);
          alertError = alertError.replace('%OBLIG%', obligation.obligationNumberFormatted);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }
  

  // method for creating association between order and invoice
  createAssociationOrderWithInvoice(order: Order, invoice: Invoice): void {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderInvoice;
      url = url.replace('%ORDER_KEY%', order.order_key.toString());

      let data: any = { order_key: order.order_key, invoice_key: invoice.invoice_key };
      // post
      this._http.post(url, data).subscribe(
        response => {
          // console.log(response);
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při vytváření vazby mezi objednávkou %ORDER% a fakturou %INVOICE%.`;
          alertError = alertError.replace('%ORDER%', order.orderNumberFormatted);
          alertError = alertError.replace('%INVOICE%', invoice.invoiceNumberFormatted);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
  }

  // method for deleting association between order and obligation
  deleteAssociationOrderWithObligation(order: Order, obligation: Obligation): void {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderObligationDel;
      url = url.replace('%ORDER_KEY%', order.order_key.toString());
      url = url.replace('%OBLIGATION_KEY%', obligation.obligation_key.toString());

      // delete
      this._http.delete(url).subscribe(
        response => {
          // console.log(response);
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při odstranění vazby mezi objednávkou %ORDER% a zakázkou %OBLIG%.`;
          alertError = alertError.replace('%ORDER%', order.orderNumberFormatted);
          alertError = alertError.replace('%OBLIG%', obligation.obligationNumberFormatted);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
  }

  // method for deleting association between order and invoice
  deleteAssociationOrderWithInvoice(order: Order, invoice: Invoice): void {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderInvoiceDel;
      url = url.replace('%ORDER_KEY%', order.order_key.toString());
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());

      // delete
      this._http.delete(url).subscribe(
        response => {
          // console.log(response);
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při odstranění vazby mezi objednávkou %ORDER% a fakturou %INVOICE%.`;
          alertError = alertError.replace('%ORDER%', order.orderNumberFormatted);
          alertError = alertError.replace('%INVOICE%', invoice.invoiceNumberFormatted);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
  }


  /**********************************************************************************/
  /* Preview PDF methods */
  /**********************************************************************************/
  // method for downloading/opening preview of order pdf
  getPreview(order: Order, lng: string): Observable<Blob> {
    let url: string = ServiceConfiguration.orders.apiOrderPreview;
    url = url.replace('%ORDER_KEY%', order.order_key.toString());
    url = url.replace('%LANG%', lng.toUpperCase().trim());
    return this._http.get(url, {responseType: 'blob'});
  }


  /**********************************************************************************/
  /* Print PDF methods */
  /**********************************************************************************/
  private _openingPrintedPDF: boolean = false;
  public get openingPrintedPDF(): boolean {
    return this._openingPrintedPDF;
  }

  // method for print order pdf to server storage
  printApiPDF(order: Order, lng: string): Observable<string> {    
    let email_id: BehaviorSubject<string> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderPrint;
      url = url.replace('%ORDER_KEY%', order.order_key.toString());

      // this._http.post(url, {language: lng.toUpperCase().trim(), files: filesArr }).subscribe(
      this._http.post(url, {language: lng.toUpperCase().trim(), files: []}).subscribe(
        response => {
          console.log(response);
          email_id.next(response);
        }, 
        error => {
          console.log(error);
          this._notificationService.alert(
            $localize`Chyba při tisku PDF faktury ` + order.orderNumberFormatted, 'error', 3500
          );
        }
      );
    }

    return email_id.asObservable();
  }

  // method for downloading/opening pdf from server storage
  getPrintedPDF(email_id: string): Observable<Blob> {
    let url: string = ServiceConfiguration.orders.apiOrderPrintOpen.replace('%EMAIL_ID%', email_id);
    return this._http.get(url, {responseType: 'blob'});
  }

  // method for downloading and opening printed PDF
  openPrintedPDF(email_id: string, order: Order): void {
    if (!email_id) return;

    this._openingPrintedPDF = true;

    this.getPrintedPDF(email_id).subscribe(
      response => {
        if (response) {
          let newBlob: any = new Blob([response], { type: (response.type ? response.type : 'application/pdf') });
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && (navigator as any).msSaveOrOpenBlob) {
            (navigator as any).msSaveOrOpenBlob(newBlob);
            return;
          }
      
          // For other browsers: 
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
      
          // var link = document.createElement('a');
          // link.href = data;
          // link.download = attachment.name;
          // // this is necessary as link.click() does not work on the latest firefox
          // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      
          // set flag after success loading
          this._openingPrintedPDF = false;

          // open new tab (does not work when ad block is activated)
          let w = window.open(data, '_blank');
          if (w) {
            setTimeout(() => w.document.title = order.orderNumberFormatted, 500);
          }
      
          // setTimeout(function () {
          //     // For Firefox it is necessary to delay revoking the ObjectURL
          //     window.URL.revokeObjectURL(data);
          //     link.remove();
          // }, 100);
        }
      },
      error => {
        console.log(error);
        // set flag after error loading
        this._openingPrintedPDF = false;
        let alert: string = $localize`Chyba při stahování PDF objednávky - ` + order.orderNumberFormatted;
        this._notificationService.alert(alert, 'error', 3500);
      }
    );
  }


  
  /**********************************************************************************/
  /* Invoice emailing service methods */
  /**********************************************************************************/
  /* Methods for email invoicing */
  private _sendingEmail: boolean = false;
  public get sendingEmail(): boolean {
    return this._sendingEmail;
  }

  private _sendCompleted: boolean = false;
  public set sendCompleted(value: boolean) {
    this._sendCompleted = value;
  }
  public get sendCompleted(): boolean {
    return this._sendCompleted;
  }

  private _sendOK: boolean = false;
  public set sendOK(value: boolean) {
    this._sendOK = value;
  }
  public get sendOK(): boolean {
    return this._sendOK;
  }

  // send email
  sendEmail(emailObj: any): Observable<any> {
    let email: BehaviorSubject<any> = new BehaviorSubject(null);

    console.log(emailObj);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._sendingEmail = true;
      
      // post data
      // this._http.post(ServiceConfiguration.emailing.invoice, emailObj).subscribe(
      let url: string = ServiceConfiguration.orders.apiOrderEmail.replace('%ORDER_KEY%', emailObj.order_key);
      this._http.post(url, emailObj).subscribe(
        response => {
          console.log(response);
          this._sendingEmail = false;
          this._sendCompleted = true;
          this._sendOK = true;
          // success alert
          let alertSuccess: string = $localize`Email byl úspěšně odeslán.`;
          this._notificationService.alert(alertSuccess, 'success', 3500);
          email.next(response);
        },
        error => {
          console.log(error);
          this._sendingEmail = false;
          this._sendCompleted = true;
          // error alert
          let alertError: string = $localize`Nastala chyba při odesílání emailu.`;
          this._notificationService.alert(alertError, 'error', 3500);
        }
      );
    }
    
    return email.asObservable();
  }

  // method for email header
  getEmailHeader(message_id: string): Observable<Email> {
    let emailHeader: BehaviorSubject<Email> = new BehaviorSubject(null);

    if (message_id && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.emailing.header.replace('%MESSAGE_ID', message_id);
      this._http.get(url).subscribe(
        response => {
          let header: Email = InvoiceTools.buildInvoiceEmail(response);
          emailHeader.next(header);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return emailHeader.asObservable();
  }

  // method for loading detail html page about email sent
  getEmailShow(message_id: string): Observable<any> {
    let showHtml: BehaviorSubject<any> = new BehaviorSubject(null);

    if (message_id && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.emailing.show.replace('%MESSAGE_ID', message_id);
      // let headers: HttpHeaders = new HttpHeaders();

      this._http.get(url, {responseType: "text"}).subscribe(
        response => {
          // console.log(response);
          showHtml.next(response);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return showHtml.asObservable();
  }

  
  // GET /orders/<ORDER_KEY>/accept-token
  createExternalToken(order_key: number): Observable<string>  {
    let result: BehaviorSubject<string> = new BehaviorSubject(null);

    if (order_key && this._authService.isAuthenticated()) {
      let url: string = ServiceConfiguration.orders.apiExternalToken;
      url = url.replace('%ORDER_KEY%', order_key.toString());

      this._http.get(url).subscribe(
        response => {
          // observable next
          result.next(response);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }
}
