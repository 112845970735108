import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DestinationRouteDataInterface } from 'src/app/interface/destination-route-data.interface';
import { ManualTrackingTargetInterface } from 'src/app/interface/manual-tracking-target.interface';
import { GeoPosition } from 'src/app/model/geo-position.object';
// import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { TrackingEvent } from 'src/app/model/tracking-event.object';
import { Vehicle } from 'src/app/model/vehicle.object';
import { DestinationRoutesService } from 'src/app/service/destination-routes.service';
import { NotificationService } from 'src/app/service/notification-service';
import { StorageService } from 'src/app/service/storage.service';
import { TrackingEventService } from 'src/app/service/tracking-event.service';
import { DateTools } from 'src/app/tools/DateTools';
import { Haversine } from 'src/app/tools/Haversine';
import { RVehicleManualTrackingConfigurationComponent } from '../r-vehicle-manual-tracking-configuration/r-vehicle-manual-tracking-configuration.component';
import { GoogleMapAdvMarker } from 'src/app/model/google-map-adv-marker.object';


@Component({
  selector: 'r-diary-mobile-map',
  templateUrl: './r-diary-mobile-map.component.html',
  styleUrls: ['./r-diary-mobile-map.component.css'],

})
export class RDiaryMobileMapComponent implements OnInit {
  private _subscribed: Array<Subscription> = [];

  private _itinerary: Itinerary = null;
  @Input()
  public set itinerary(value: Itinerary) {
    this._itinerary = value;
    this._itineraryMarker = [];
    this._itineraryMarker.push(this.createMapMarker(this._itinerary.gps_coord.googleLatLang, this._itinerary));
    this.loadTrackingData(this._itinerary);
    if(!this.itinerary.hasStarted){
      this.setVehicleTarget(this.itinerary);
    }
  }
  public get itinerary():Itinerary {
    return this._itinerary;
  }

  private _heightMap: string = '500px';
  @Input()
  public set heightMap(value: string) {
    this._heightMap = value;
  }
  public get heightMap(): string {
    return this._heightMap;
  }



  // output for binding map should be hidden from parent component logic
  @Output() hideMap: EventEmitter<boolean>  = new EventEmitter<boolean>();

  // array because of google map component
  private _itineraryMarker: Array<GoogleMapAdvMarker> = [];
  public get itineraryMarker(): Array<GoogleMapAdvMarker> {
    return this._itineraryMarker;
  }

  private _markerDestRoute: GoogleMapAdvMarker;
  public get markerDestRoute(): GoogleMapAdvMarker {
    return this._markerDestRoute;
  }

  // tracking events of currently clicked itinerary
  private _trackingEvents: Array<TrackingEvent> = [];
  get trackingEvents(): Array<TrackingEvent> {
    return this._trackingEvents;
  }

  get loadingTrackingEvents(): boolean {
    return this._trackingEventsServ.loadingTrackingEvents;
  }

  constructor(
    private _trackingEventsServ: TrackingEventService,
    private _destinationRoutesService: DestinationRoutesService,
    private _notificationServ: NotificationService,
    private _storage: StorageService
  ) { 
  }

  ngOnInit(): void {

  }
  
  ngOnDestroy() {
    this._subscribed.forEach(
      subscriber => subscriber.unsubscribe()
    );
  }

  // method for creating new marker on google map
  private createMapMarker(location: any, it: Itinerary = null): GoogleMapAdvMarker {
    let marker = new GoogleMapAdvMarker();
    marker.position = location;
    marker.draggable = false;
    marker.setData('type', 2);
    marker.setData('id', Math.random().toString(36).substring(7));
    marker.icon = it.typeIcon();
    return marker;
  }

  // method for loading obligations tracking data to displaying on google map
  public loadTrackingData(itinerary: Itinerary): void {
    if (itinerary && itinerary.agenda && itinerary.agenda.order_number) {
      // using today should be okay
      let time_from_date: Date = new Date();
      // resize tf interval (without that some events are missing)
      let sevenDaysLess: Date = time_from_date;
      sevenDaysLess.setDate(sevenDaysLess.getDate() - 7);

      let filterObj: any = {
        tf: DateTools.toCustomIsoApiFormat(sevenDaysLess), //'2019-09-24T10:15:00.000%2B0200',
        on: itinerary.agenda.order_number
      };

      this._subscribed.push(
        this._trackingEventsServ.getTrackingEvents(filterObj).subscribe(
          (response: Array<TrackingEvent>) => {
            if (response && response.length) {
              this._trackingEvents = response;
            }
          }
        )
      )
    }
  }

  /****************************************************/
  /* Destination route creating */
  /****************************************************/
  private _temporaryTrackingTarget: ManualTrackingTargetInterface;
  private _temporaryTrackingWaypoints: Array<any> = [];
  private _currentVehicle: Vehicle;

  // method for setting of destination route to be created
  setVehicleTarget(itinerary: Itinerary): void {
    if (!itinerary.gps_coord || !itinerary.gps_coord.googleLatLang) {
      this._notificationServ.alert($localize`Chybějící souřadnice!`, 'error', 4000);
      return;
    }

    this._currentVehicle = null;
    if (itinerary.agenda && itinerary.agenda.vehicle) {
      this._currentVehicle = itinerary.agenda.vehicle;
    }
    else {
      // alert?
      return;
    }

    // dest route marker
    this._markerDestRoute = new GoogleMapAdvMarker();
    // this._markerDestRoute.position = this._itineraryMarker[0].position;
    this._markerDestRoute.position = itinerary.gps_coord.googleLatLang;
    this._markerDestRoute.icon = GoogleMapAdvMarker.icons['C'];
    this._markerDestRoute.draggable = false;
    this._markerDestRoute.setData('type', 2);
    this._markerDestRoute.setData('id', Math.random().toString(36).substring(7));
    // this._itineraryMarker.push(this._markerDestRoute);

    // turn on manual tracking mode
    itinerary.manualTrackingMode = true;

    this._temporaryTrackingTarget = {
      address: itinerary.address,
      searchedAddress: itinerary.address,
      distance: 0,
      duration: 0,
      durationReal: 0,
      targetReachedMessageSent: false,
      stops: 0,
      targetTolerance: 2,  //this._targetTolerance,
      lat: this._markerDestRoute.latitude, // .position.lat(),
      lng: this._markerDestRoute.longitude // position.lng()
    };
  }

  mapClose(){
    this.hideMap.emit(true);
  }

  // method for saving destination route via api
  saveVehicleTarget(itinerary: Itinerary) {
    if (!this._itineraryMarker.length) {
      // alert when itinerary object is missing?
      return;
    }
      
    let endPosition: GeoPosition = new GeoPosition();
    endPosition.pos_gps = this._markerDestRoute.latitude + ',' + this._markerDestRoute.longitude;

    let waypoints: Array<GeoPosition> = this._temporaryTrackingWaypoints.map(
      (waypoint) => {
        let geoPosition = new GeoPosition();
        const position = waypoint.location;
        let lat = typeof position.lat === "function" ? position.lat() : position.lat;
        let lng = typeof position.lng === "function" ? position.lng() : position.lng;
        geoPosition.pos_gps = lat + ',' + lng;
        // geoPosition.pos_gps = waypoint.location.lat() + ',' + waypoint.location.lng();
        return geoPosition;
      }
    );

    // save route via api
    this._destinationRoutesService.createNewRoute(
      this._currentVehicle,
      endPosition,
      waypoints,
      2, // targetTolerance 2km
      this._temporaryTrackingTarget.duration,
      this._temporaryTrackingTarget.distance,
      this._temporaryTrackingTarget.searchedAddress
    ).subscribe(
      (data: DestinationRouteDataInterface) => {
        // show success alert
        let alertSuccess: string = $localize`Dojezdový cíl byl úspěšně vytvořen.`; 
        this._notificationServ.alert(alertSuccess, 'success', 3000);

        DestinationRoutesService.setReachDestinationToVehicle(
          this._currentVehicle,
          waypoints,
          this._temporaryTrackingTarget.duration,
          this._temporaryTrackingTarget.duration,
          endPosition,
          this._temporaryTrackingTarget.distance,
          2, // targetTolerance 2km
          new Date(DateTools.isoFix(data.time_created).getTime()),
          this._temporaryTrackingTarget.searchedAddress,
          0,
          '#ff0000'
        );
        this._currentVehicle.destRouteChange.subscribe(
          () => {
           
            this._storage.setItem(
              RVehicleManualTrackingConfigurationComponent.createKey(this._currentVehicle),
              this._currentVehicle.getTemporaryManualTrackingDataToCache()
            );
          }
        );

        // turn off manual tracking mode
        itinerary.manualTrackingMode = false;
      },
      error => {
        // show error alert
        let alertError: string = $localize`Chyba při vytváření dojezdového cíle.`;
        this._notificationServ.alert(alertError, 'error', 3000);
      }
    );
  }

  directionsChange(manualTrackingDirectionRendered: any) {
    if (this._currentVehicle) {
      let fullDuration = manualTrackingDirectionRendered.getDirections().routes[0].legs[0].duration.value;
      // speed_coef now used after GET request
      // if (!isNaN(this._currentVehicle.speed_coef)) {
      //   fullDuration = fullDuration / this._currentVehicle.speed_coef;
      // }
      this._temporaryTrackingWaypoints = manualTrackingDirectionRendered.getDirections().request.waypoints;
      this._temporaryTrackingTarget = {
        address: this._temporaryTrackingTarget.address,
        searchedAddress: this._temporaryTrackingTarget.searchedAddress,
        duration: fullDuration,
        durationReal: fullDuration,
        distance: manualTrackingDirectionRendered.getDirections().routes[0].legs[0].distance.value,
        targetReachedMessageSent: false,
        stops: this._temporaryTrackingTarget.stops,
        targetTolerance: this._temporaryTrackingTarget.targetTolerance,
        lat: this._temporaryTrackingTarget.lat,
        lng: this._temporaryTrackingTarget.lng
      };
    }
  }
  
  // method for determinng if itinerary is the same as destination route target
  isTargetForItinerary(itinerary: Itinerary): boolean {
    if (itinerary && itinerary.gps_coord && itinerary.agenda && itinerary.agenda.vehicle) {
      if (itinerary.agenda.vehicle.temporaryManualTrackingTarget) {
        let dist = Haversine.haversine(
          {
            latitude: itinerary.agenda.vehicle.temporaryManualTrackingTarget.lat,
            longitude: itinerary.agenda.vehicle.temporaryManualTrackingTarget.lng
          },
          {
            latitude: itinerary.gps_coord.googleLatLang.lat,
            longitude: itinerary.gps_coord.googleLatLang.lng
          }
        );
        // distance closer than 1 km
        if (dist < 1) {
          return true;
        }
      }
    }
    return false;
  }

}
