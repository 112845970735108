export class InvoicingSettings {
  public readonly SENDING_TYPE: any = {
    EMAIL: 'email',
    POST: 'post',
    EDOC: 'edoc'
  } 

  private _global_settings: boolean = true;
  public get global_settings(): boolean {
    return this._global_settings;
  }
  public set global_settings(value: boolean) {
    this._global_settings = value;
  }

  private _invoice_maturity: number;
  public get invoice_maturity(): number {
    return this._invoice_maturity;
  }
  public set invoice_maturity(value: number) {
    this._invoice_maturity = value;
  }
  
  private _invoice_sending: string = null;
  public get invoice_sending(): string {
    return this._invoice_sending;
  }
  public set invoice_sending(value: string) {
    this._invoice_sending = value;
  }

  private _invoice_email: string;
  public get invoice_email(): string {
    return this._invoice_email;
  }
  public set invoice_email(value: string) {
    this._invoice_email = value;
  }
  
  private _invoice_email_subject: string;
  public get invoice_email_subject(): string {
    return this._invoice_email_subject;
  }
  public set invoice_email_subject(value: string) {
    this._invoice_email_subject = value;
  }

  private _invoice_pdf_name: string;
  public get invoice_pdf_name(): string {
    return this._invoice_pdf_name;
  }
  public set invoice_pdf_name(value: string) {
    this._invoice_pdf_name = value;
  }

  private _documents_sending: string = null;
  public get documents_sending(): string {
    return this._documents_sending;
  }
  public set documents_sending(value: string) {
    this._documents_sending = value;
  }

  private _documents_email: string;
  public get documents_email(): string {
    return this._documents_email;
  }
  public set documents_email(value: string) {
    this._documents_email = value;
  }

  private _documents_email_subject: string;
  public get documents_email_subject(): string {
    return this._documents_email_subject;
  }
  public set documents_email_subject(value: string) {
    this._documents_email_subject = value;
  }

  private _documents_invoice_combine: string;
  public get documents_invoice_combine(): string {
    return this._documents_invoice_combine;
  }
  public set documents_invoice_combine(value: string) {
    this._documents_invoice_combine = value;
  }
  
  // private _documents_invoice_combine: string;

  public get apiObject(): any {
    return {
      global_settings: this._global_settings,
      invoice_maturity: this._invoice_maturity,
      invoice_sending: this._invoice_sending,
      invoice_email: this._invoice_email,
      invoice_email_subject: this._invoice_email_subject,
      invoice_pdf_name: this._invoice_pdf_name,
      documents_sending: this._documents_sending,
      documents_email: this._documents_email,
      documents_email_subject: this._documents_email_subject,
      documents_invoice_combine: this._documents_invoice_combine,
    };
  }
}