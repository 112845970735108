import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IS_DEMO, ServiceConfiguration } from "../config";
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';
import { ExternalImportAvailable } from '../model/external-import-available.object';
import { ExternalImport } from '../model/external-import.object';
import { Order } from '../model/order.object';
import { OrderTools } from '../tools/OrderTools';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  private _loadingExtImportsAvailable: boolean = false;
  public get loadingExtImportsAvailable(): boolean {
    return this._loadingExtImportsAvailable;
  }
  
  private _loadingExtImportsSetup: boolean = false;
  public get loadingExtImportsSetup(): boolean {
    return this._loadingExtImportsSetup;
  }
  
  private _loadingExtImportsCars: boolean = false;
  public get loadingExtImportsCars(): boolean {
    return this._loadingExtImportsCars;
  }
  
  private _loadingExtOrder: boolean = false;
  public get loadingExtOrder(): boolean {
    return this._loadingExtOrder;
  }

  constructor(
    private _http: HttpClientService,
    private _authServ: AuthenticationService,
    private _notificationServ: NotificationService
  ) {
  }

  /*******************************************/
  /* Supported external fms imports */
  /*******************************************/
  // GET /external/import/available
  getExternalImportsAvailable(): Observable<Array<ExternalImportAvailable>> {
    let res: BehaviorSubject<Array<ExternalImportAvailable>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.external.apiImportAvailable;
      this._loadingExtImportsAvailable = true;

      this._http.get(url).subscribe(
        response => {
          let imports = this.buildExternalImportsAvailableFromData(response);
          res.next(imports);
          this._loadingExtImportsAvailable = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loadingExtImportsAvailable = false;
        },
      );
    }

    return res.asObservable();
  }

  
  /*******************************************/
  /* External fms setup imports */
  /*******************************************/
  // GET /external/import/
  getExternalImports(): Observable<Array<ExternalImport>> {
    let res: BehaviorSubject<Array<ExternalImport>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.external.apiImport;
      this._loadingExtImportsSetup = true;

      this._http.get(url).subscribe(
        response => {
          let imports = this.buildExternalImportsFromData(response);
          res.next(imports);
          this._loadingExtImportsSetup = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loadingExtImportsSetup = false;
        },
      );
    }

    return res.asObservable();
  }

  // POST /external/import/
  createExternalImport(setup: ExternalImport): Observable<ExternalImport> {
    let created: BehaviorSubject<ExternalImport> = new BehaviorSubject(null);

    if (setup && this._authServ.isAuthenticated()) {
      let url: string = ServiceConfiguration.external.apiImport;

      this._http.post(url, setup.apiObject).subscribe(
        response => {
          // alert
          let alert: string = $localize`Zdroj %TYPE% byl úspěšně nastaven.`;
          alert = alert.replace('%TYPE%', setup.import_type);
          this._notificationServ.alert(alert, 'success', 4000);
          
          // observable next
          created.next(this.buildExternalImport(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alert: string = $localize`Chyba při nastavení zdroje %TYPE%.`;
          alert = alert.replace('%TYPE%', setup.import_type);
          this._notificationServ.alert(alert, 'error', 4000);
        }
      );
    }

    return created.asObservable();
  }

  // PUT /external/import/<type>
  updateExternalImport(setup: ExternalImport): Observable<ExternalImport> {
    let updated: BehaviorSubject<ExternalImport> = new BehaviorSubject(null);

    if (setup && this._authServ.isAuthenticated()) {
      let url: string = ServiceConfiguration.external.apiImport + setup.import_type;

      this._http.put(url, setup.apiObject).subscribe(
        response => {
          // alert
          let alert: string = $localize`Zdroj %TYPE% byl úspěšně nastaven.`;
          alert = alert.replace('%TYPE%', setup.import_type);
          this._notificationServ.alert(alert, 'success', 4000);
          
          // observable next
          updated.next(this.buildExternalImport(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alert: string = $localize`Chyba při nastavení zdroje %TYPE%.`;
          alert = alert.replace('%TYPE%', setup.import_type);
          this._notificationServ.alert(alert, 'error', 4000);
        }
      );
    }

    return updated.asObservable();
  }
  
  // DELETE /external/import/<type>
  deleteExternalImportSetup(setup: ExternalImport): Observable<any> {
    let res: BehaviorSubject<any> = new BehaviorSubject(null);
    if (setup && this._authServ.isAuthenticated()) {
      let url: string = ServiceConfiguration.external.apiImport;
      url += setup.import_type;

      this._http.delete(url).subscribe(
        response => {
          console.log(response);
          // alert
          let alert: string = $localize`Zdroj %TYPE% byl úspěšně odstraněn.`;
          alert = alert.replace('%TYPE%', setup.import_type);
          this._notificationServ.alert(alert, 'success', 4000);
          res.next(response);
        },
        error => {
          //handle error
          console.error(error);
          // alert
          let alert: string = $localize`Chyba při odstranění zdroje %TYPE%.`;
          alert = alert.replace('%TYPE%', setup.import_type);
          this._notificationServ.alert(alert, 'error', 4000);
        }
      );
    }
    return res.asObservable();
  }


  /*******************************************/
  /* External car imports */
  /*******************************************/
  // GET /external/import/<type>/car
  getExternalImportsCars(type: string): Observable<Array<ExternalImport>> {
    let res: BehaviorSubject<Array<ExternalImport>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.external.apiImportCar;
      url = url.replace("%TYPE%", type);
      this._loadingExtImportsCars = true;

      this._http.get(url).subscribe(
        response => {
          let imports = this.buildExternalImportsFromData(response);
          res.next(imports);
          this._loadingExtImportsCars = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loadingExtImportsCars = false;
        },
      );
    }

    return res.asObservable();
  }
  
  // POST /external/import/<type>/car
  createExternalImportsCars(ext_import: ExternalImport): Observable<ExternalImport> {
    let res: BehaviorSubject<ExternalImport> = new BehaviorSubject(null);

    if (ext_import && this._authServ.isAuthenticated()) {
      let url: string = ServiceConfiguration.external.apiImportCar;
      url = url.replace("%TYPE%", ext_import.import_type);

      // specify allowed attributes for creating external car import
      let data: any = {
        car_key: ext_import.car_key,
        ext_id: ext_import.ext_id
        // import_type: ext_import.import_type,
        // auto_add_cars: false,
        // auto_add_drivers: false
      }

      console.log(url);
      console.log(data);

      this._http.post(url, data).subscribe(
        response => {
          let alert: string = $localize`Vytvoření externího importu vozidla proběhlo úspěšně.`;
          this._notificationServ.alert(alert, 'success', 4000);

          let i = this.buildExternalImport(response);
          res.next(i);
        },
        error => {
          // handle error
          console.log(error);
          let alert: string = $localize`Chyba při vytváření externího importu vozidla - kontaktujte podporu.`;
          this._notificationServ.alert(alert, 'error', 4000);
        },
      );
    }

    return res.asObservable();
  }

  // PUT /external/import/<type>/car/<ext_id>
  updateExternalImportsCars(ext_import: ExternalImport): Observable<ExternalImport> {
    let res: BehaviorSubject<ExternalImport> = new BehaviorSubject(null);

    if (ext_import && this._authServ.isAuthenticated()) {
      let url: string = ServiceConfiguration.external.apiImportCar;
      url = url.replace("%TYPE%", ext_import.import_type);
      url += '/' + ext_import.ext_id;

      // specify allowed attributes for updating external car import
      let data: any = {
        ext_id: ext_import.ext_id,
        car_key: ext_import.car_key,
        // auto_add_cars: ext_import.auto_add_cars,
        // auto_add_drivers: ext_import.auto_add_drivers
      }

      this._http.put(url, data).subscribe(
        response => {
          let alert: string = $localize`Úprava externího importu vozidla proběhla úspěšně.`;
          this._notificationServ.alert(alert, 'success', 4000);

          let i = this.buildExternalImport(response);
          res.next(i);
        },
        error => {
          // handle error
          console.log(error);
          let alert: string = $localize`Chyba při úpravě externího importu vozidla - kontaktujte podporu.`;
          this._notificationServ.alert(alert, 'error', 4000);
        },
      );
    }

    return res.asObservable();
  }

  // DELETE /external/import/<type>/car/<ext_id>
  deleteExternalImportCar(ext_import: ExternalImport): Observable<any> {
    let res: BehaviorSubject<any> = new BehaviorSubject(null);
    if (ext_import && this._authServ.isAuthenticated()) {
      let url: string = ServiceConfiguration.external.apiImportCar;
      url = url.replace("%TYPE%", ext_import.import_type);
      url += '/' + ext_import.ext_id;

      this._http.delete(url).subscribe(
        response => {
          console.log(response);
          let alert: string = $localize`Externí import vozidla byl úspěšně odstraněn.`;
          this._notificationServ.alert(alert, 'success', 4000);
          res.next(true);
        },
        error => {
          //handle error
          console.error(error);
          let alert: string = $localize`Chyba při úpravě odstranění importu vozidla - kontaktujte podporu.`;
          this._notificationServ.alert(alert, 'error', 4000);
        }
      );
    }
    return res.asObservable();
  }


  /*******************************************/
  /* External orders */
  /*******************************************/
  // GET /external/orders/?token=<TOKEN>&order=<ORDER>
  getExternalOrder(token: string, order: string): Observable<Order> {
    let res: BehaviorSubject<Order> = new BehaviorSubject(null);
    if (this._authServ.isAuthenticated()) {
      let url: string = ServiceConfiguration.external.apiOrder;
      url = url.replace("<TOKEN>", token);
      url = url.replace("<ORDER>", order);
      this._loadingExtOrder = true;

      this._http.get(url).subscribe(
        response => {
          res.next(OrderTools.buildOrder(response));
          this._loadingExtOrder = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loadingExtOrder = false;
        }
      );
    }

    return res.asObservable();
  }
  
  // GET /external/orders/pdf?token=<TOKEN>&order=<ORDER>
  getExternalOrderPdf(token: string | null, order: string | null): Observable<any> {
    let url: string = ServiceConfiguration.external.apiOrderPdf;
    if (token && order) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<ORDER>', order);
    }

    return this._http.get(url, {responseType: 'blob'});
  }

  
  // method for creating external imports available array
  private buildExternalImportsAvailableFromData(data: Array<any>): any {
    let tasks: Array<ExternalImportAvailable> = [];
    data.forEach(
      o => {
        let task: ExternalImportAvailable = this.buildExternalImportAvailable(o);
        tasks.push(task);
      }
    );
    return tasks;
  }

  // method for creating a single external import available object
  private buildExternalImportAvailable(o: any): ExternalImportAvailable {
    let task: ExternalImportAvailable = new ExternalImportAvailable();
    for (let key in o) {
      task[key] = o[key];
    }
    return task;
  }

  // method for creating external imports array
  private buildExternalImportsFromData(data: Array<any>): any {
    let tasks: Array<ExternalImport> = [];
    data.forEach(
      o => {
        let task: ExternalImport = this.buildExternalImport(o);
        tasks.push(task);
      }
    );
    return tasks;
  }

  // method for creating a single external import available object
  private buildExternalImport(o: any): ExternalImport {
    let task: ExternalImport = new ExternalImport();
    for (let key in o) {
      task[key] = o[key];
    }
    return task;
  }
}
