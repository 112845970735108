// import {PersonDataInterface} from "../interface/person-data.interface";

export class Person {

  constructor() {
  }

  private _book_key: number;
  get book_key(): number {
    return this._book_key;
  }
  set book_key(value: number) {
    this._book_key = value;
  }

  private _person_key: number;
  get person_key(): number {
    return this._person_key;
  }
  set person_key(value: number) {
    this._person_key = value;
  }

  private _importance: number;
  get importance(): number {
    return this._importance;
  }
  set importance(value: number) {
    this._importance = value;
  }

  private _name: string;
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }

  private _send_offers: boolean;
  get send_offers(): boolean {
    return this._send_offers;
  }
  set send_offers(value: boolean) {
    this._send_offers = value;
  }

  private _sex: string = '';
  get sex(): string {
    return this._sex;
  }
  set sex(value: string) {
    this._sex = value;
  }

  private _languages: string = '';
  get languages(): string {
    return this._languages;
  }
  set languages(value: string) {
    this._languages = value;
  }

  private _position: string = '';
  get position(): string {
    return this._position;
  }
  set position(value: string) {
    this._position = value;
    if (this._position == 'M') this._positionName = $localize`Jednatel/Vedoucí`;
    else if (this._position == 'I') this._positionName = $localize`Účetní/Fakturant`;
    else if (this._position == 'D') this._positionName = $localize`Dispečer`;
    else if (this._position == 'S') this._positionName = $localize`Speditér`;
    else if (this._position == 'O') this._positionName = $localize`Ostatní`;
  }

  private _positionName: string = '';
  public get positionName(): string {
    return this._positionName;
  }

  private _phone: string = '';
  get phone(): string {
    return this._phone;
  }
  set phone(value: string) {
    this._phone = value;
  }

  private _mobile: string = '';
  get mobile(): string {
    return this._mobile;
  }
  set mobile(value: string) {
    this._mobile = value;
  }

  private _email: string = '';
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }

  private _fax: string = '';
  get fax(): string {
    return this._fax;
  }
  set fax(value: string) {
    this._fax = value;
  }

  // custom attribute used for filtering cars in vehicle_list, dispatcher_board, map-all-cars
  private _marked: boolean;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }

  // custom for reminder switch purpose
  private _send_reminder: boolean = false;
  public get send_reminder(): boolean {
    return this._send_reminder;
  }
  public set send_reminder(value: boolean) {
    this._send_reminder = value;
  }

  
  get apiObject(): any {
    return {
      importance: this.importance,
      name: this.name,
      send_offers: this.send_offers,
      sex: this.sex,
      languages: this.languages,
      position: this.position,
      phone: this.phone,
      mobile: this.mobile,
      email: this.email,
      fax: this.fax
    };
  }
}