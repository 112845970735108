<div *ngIf="sending" class="container text-center py-3">
  <h6 i18n>Aktuálně probíhá odesílání emailu...</h6>
  <h6 i18n>Může to trvat i několik sekund...</h6>
  <div class="spinner-wrapper py-4">
      <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
          <span class="sr-only"></span>
      </div>
  </div>
</div>

<div *ngIf="!sending && sendCompleted && sendOK" class="container text-center py-3">
  <h6>
    <ng-container i18n>Email byl úspěšně odeslán na emailovou adresu</ng-container>: 
    <div *ngFor="let email of multipleContactsTo">{{email}}</div>
  </h6>
</div>

<div *ngIf="!sending && sendCompleted && !sendOK" class="container text-center py-3">
  <h6 class="text-danger" i18n>Nastala chyba při odesílání emailu!</h6>
  <h6 i18n>Obnovte stránku a zkuste email odeslat znovu, případně kontaktujte podporu.</h6>
</div>

<div *ngIf="!sending && !sendCompleted" class="container">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-md-9">
          <div *ngIf="!company.send_emails_from_their_domain" class="form-group mb-1">
            <label class="mb-0" i18n>Odesílatel</label>
            <input class="form-control form-control-sm" type="text" 
            value="info@truckmanager.eu" readonly/>
          </div>
          <div *ngIf="company.send_emails_from_their_domain" class="form-group mb-1">
            <label class="mb-0" i18n>Odesílatel</label>
            <input class="form-control form-control-sm" type="text"
            [class.border-danger]="!validEmailAddress(contactFrom)"
            placeholder="Zvolte nebo napište" i18n-placeholder
            [(ngModel)]="contactFrom" 
            [disabled]="!company.send_emails_from_their_domain"
            [ngbTypeahead]="autoCompleteContactFrom"
            [resultFormatter]="resultFormatListContactFrom"
            [inputFormatter]="inputFormatListValueContactFrom"
            (focus)="focusContactFrom$.next($event.target.value)"
            (click)="clickContactFrom$.next($event.target.value)"
            #autocompleteContactFrom="ngbTypeahead"/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>Jazyk</label>
            <select class="form-control form-control-sm" 
            [(ngModel)]="languageEmail" (change)="initEmail(true)">
            <option value="CZ">CZ</option>
            <option value="SK">SK</option>
            <option value="EN">EN</option>
            <option value="DE">DE</option>
            <option value="ES">ES</option>
            <option value="IT">IT</option>
            <option value="PL">PL</option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group mb-1">
        <div class="d-flex justify-content-start mb-0">
          <div class="mr-2" i18n>Kopie</div>
          <div class="custom-control custom-switch" placement="bottom auto" container="body"
          ngbTooltip="Kopie emailu bude odeslána na zadaný email" i18n-ngbTooltip>
            <input class="custom-control-input" type="checkbox" id="checkCopy" 
            [(ngModel)]="sendCopy" (ngModelChange)="sendCopyChange()"/>
            <label class="custom-control-label text-nowrap normal-font-size mb-0" for="checkCopy">
            </label>
          </div>
        </div>

        <div *ngFor="let email of multipleCopyTo; let i=index; trackBy:trackByIndex;" class="input-group input-group-sm mb-1">
          <input class="form-control form-control-sm" type="text"
          [class.border-danger]="!validEmailAddress(multipleCopyTo[i])"
          placeholder="Napište email" i18n-placeholder [(ngModel)]="multipleCopyTo[i]"/>

          <div class="input-group-append">
            <button class="btn btn-sm btn-outline-danger" type="button" (click)="removeContactCopy(email)">
              - <ng-container i18n>odebrat kopii</ng-container>
            </button>
          </div>
        </div>
        
        <div class="input-group input-group-sm">
          <input class="form-control form-control-sm" type="text"
          [class.border-danger]="!validEmailAddress(contactCopy)"
          placeholder="Zvolte nebo napište" i18n-placeholder
          [(ngModel)]="contactCopy" 
          (ngModelChange)="copyChange()"
          [ngbTypeahead]="autoCompleteContactCopy"
          [resultFormatter]="resultFormatListContactCopy"
          [inputFormatter]="inputFormatListValueContactCopy"
          (focus)="focusContactCopy$.next($event.target.value)"
          (click)="clickContactCopy$.next($event.target.value)"
          (keyup)="onKeyUpCopy($event)"
          #autocompleteContactCopy="ngbTypeahead"/>
          
          <div class="input-group-append">
            <button class="btn btn-sm btn-outline-primary" type="button" (click)="addContactCopy()">
              + <ng-container i18n>přidat kopii</ng-container>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="form-group mb-1">
        <div class="d-flex justify-content-start mb-0">
          <div class="mr-2" i18n>Kopie</div>
          <div class="custom-control custom-switch" placement="bottom auto" container="body"
          ngbTooltip="Kopie emailu bude odeslána na zadaný email" i18n-ngbTooltip>
            <input class="custom-control-input" type="checkbox" id="checkCopy" [(ngModel)]="sendCopy"/>
            <label class="custom-control-label text-nowrap normal-font-size mb-0" for="checkCopy">
            </label>
          </div>
        </div>
        <input class="form-control form-control-sm" type="text"
        placeholder="Zvolte nebo napište" i18n-placeholder
        [(ngModel)]="contactCopy" 
        [ngbTypeahead]="autoCompleteContactCopy"
        [resultFormatter]="resultFormatListContactCopy"
        [inputFormatter]="inputFormatListValueContactCopy"
        (focus)="focusContactCopy$.next($event.target.value)"
        (click)="clickContactCopy$.next($event.target.value)"
        #autocompleteContactCopy="ngbTypeahead"/>
      </div> -->
      
      <div class="form-group mb-1">
        <label class="mb-0" i18n>Příjemce</label>
        
        <div *ngFor="let email of multipleContactsTo; let i=index; trackBy:trackByIndex;" class="input-group input-group-sm mb-1">
          <input class="form-control form-control-sm" type="text"
          placeholder="Napište email příjemce" i18n-placeholder [(ngModel)]="multipleContactsTo[i]"/>

          <div class="input-group-append">
            <button class="btn btn-sm btn-outline-danger" type="button" (click)="removeContactTo(email)">
              - <ng-container i18n>odebrat příjemce</ng-container>
            </button>
          </div>
        </div>
        
        <div class="input-group input-group-sm">
          <input class="form-control form-control-sm" type="text"
          [class.border-danger]="!validEmailAddress(contactTo)"
          placeholder="Zvolte nebo napište" i18n-placeholder
          [(ngModel)]="contactTo"
          [ngbTypeahead]="autoCompleteContactTo"
          [resultFormatter]="resultFormatListContactTo"
          [inputFormatter]="inputFormatListValueContactTo"
          (selectItem)="onSelectContactTo($event);"
          (focus)="focusContactTo$.next($event.target.value)"
          (click)="clickContactTo$.next($event.target.value)"
          (keyup)="onKeyUp($event)"
          #autocompleteContactTo="ngbTypeahead"/>
          
          <div class="input-group-append">
            <button class="btn btn-sm btn-outline-primary" type="button" (click)="addContactTo()">
              + <ng-container i18n>přidat příjemce</ng-container>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group mb-1">
        <label class="text-nowrap normal-font-size mb-0" i18n>Předmět e-mailu</label>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="subjectEmail"/>
      </div>

      <div class="form-group mb-1">
        <label class="normal-font-size mb-0" i18n>Text e-mailu</label>
        <div class="p-1 border rounded content-editable" style="height:120px; overflow: auto"
        contenteditable="true"
        [contenteditableHtml]="true"
        [contenteditableModel]="textEmail"
        (contenteditableModelChange)="afterTextEmailChange($event)"></div>
        
        <!-- <div class="p-1 border rounded content-editable" style="height:120px; overflow: auto"
        contenteditable="true"
        [innerHTML]="textEmail | safeHtml"
        (contenteditableModel)="textEmail"></div> -->
        <!-- [innerHTML]="textEmail | safeHtml"
        (input)="textEmail=$event.target.innerHTML"></div> -->
      </div>

      <div class="form-group mb-1">
        <label class="normal-font-size mb-0" i18n>Váš podpis</label>
        <div class="p-1 border rounded content-editable" style="height:100px; overflow: auto"
        contenteditable="true"
        [contenteditableHtml]="true"
        [contenteditableModel]="signatureEmail"
        (contenteditableModelChange)="afterSignatureEmailChange($event)"></div>

        <!-- <div class="p-1 border rounded content-editable" style="height:100px; overflow: auto"
        contenteditable="true"
        [innerHTML]="signatureEmail | safeHtml"
        (contenteditableModel)="signatureEmail"></div> -->
        <!-- [innerHTML]="signatureEmail | safeHtml"
        (input)="signatureEmail=$event.target.innerHTML"></div> -->
      </div>
    </div>
  </div>
  
  <div class="row mb-2">
    <div class="col-12">
      <div class="bg-warning border rounded shadow mt-3 px-3">
        <h6 i18n>Přiložené soubory</h6>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let attachment of attachments" class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
      <div class="border rounded py-2 h-100">
        <div class="text-truncate px-2">
          <div class="custom-control custom-switch">
            <input class="custom-control-input" type="checkbox" [id]="'check-' + attachment.name"
            [(ngModel)]="attachment.isAttached" (ngModelChange)="onChangeAttached()"/>
            <label class="custom-control-label text-nowrap normal-font-size" 
            [for]="'check-' + attachment.name" container="body" [ngbTooltip]="attachmentName">
              {{attachment.name}}
            </label>
          </div>
          <ng-template #attachmentName>
            <ng-container i18n>Soubor</ng-container> <strong>{{attachment.name}}</strong>
          </ng-template>
        </div>
        <div class="text-center p-0 px-2 mb-2 position-relative">
          <!-- file size info -->
          <span class="file-size badge badge-dark">
            {{attachment.sizeMB | number:'1.1-1'}}MB
          </span>

          <!-- file download feature -->
          <img class="download-file" src="assets/icons-new/download.svg" height="20px"
          (click)="downloadAttachment(attachment)"
          ngbTooltip="Stáhnout soubor" i18n-ngbTooltip container="body" placement="top auto"/>

          <!-- file origin icon -->
          <img *ngIf="attachment.origin == 'USER'" class="file-origin"
          src="assets/icons-new/user.svg" height="20px"
          ngbTooltip="Zdroj: TruckAgenda" i18n-ngbTooltip
          container="body" placement="top auto"/>
          <img *ngIf="attachment.origin == 'TM'" class="file-origin"
          src="assets/icons-new/small_truck.svg" height="20px"
          ngbTooltip="Zdroj: Aplikace TM" i18n-ngbTooltip
          container="body" placement="top auto"/>
          <img *ngIf="attachment.origin == 'WEB_TM'" class="file-origin"
          src="assets/icons-new/small_truck.svg" height="20px"
          ngbTooltip="Zdroj: Web TM" i18n-ngbTooltip
          container="body" placement="top auto"/>
          <img *ngIf="attachment.origin == 'EXTERNAL'" class="file-origin" 
          src="assets/icons-new/digitalni-objednavka.svg" height="20px"
          ngbTooltip="Zdroj: Digitální objednávka" i18n-ngbTooltip
          container="body" placement="top auto"/>
          <img *ngIf="attachment.origin == 'SCANNER'" class="file-origin" 
          src="assets/icons-new/tm-scanner.svg" height="20px"
          ngbTooltip="Zdroj: TM scanner" i18n-ngbTooltip
          container="body" placement="top auto"/>

          <a href="javascript:void(0)" (click)="openAttachmentNewTab(attachment)">
            <div *ngIf="attachment.thumbnail" class="thumbnail shadow-sm">
              <img class="thumbnail" [ngbTooltip]="attachment.name"
              [src]="attachment.thumbnail.content | safeResource" alt="Náhled"/>
            </div>
            <div *ngIf="!attachment.thumbnail" class="thumbnail shadow-sm">
              <img class="thumbnail" [ngbTooltip]="attachment.name" 
              src="assets/icons-new/folder_document.svg" alt="Náhled"/>
            </div>
          </a>
          <ng-template #attachmentOpen>
            <div><strong>{{attachment.name}}</strong></div>
            <div i18n>Kliknutím otevřete soubor</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 my-3">
      <div *ngIf="attachedFilesOversize" class="col-sm-12 mt-4">
        <h5 class="text-danger">
          <ng-container i18n>Překročena velikost přiložených souborů - fakturu není možné odeslat emailem.</ng-container>
        </h5>
      </div>
      <button class="btn btn-success form-control" type="button" (click)="sendEmail()"
      [disabled]="attachedFilesOversize || !validContactFrom(contactFrom) || !validEmailAddress(contactTo)">
        <ng-container i18n>Odeslat</ng-container>
      </button>
    </div>
  </div>
</div>