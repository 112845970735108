import { DateTools } from "../tools/DateTools";
import { Vehicle } from "./vehicle.object";

export class ExternalImport {
  constructor() {
  }

  /***********************************************/
  /* Common attributes */
  /***********************************************/
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _import_type: string;
  public get import_type(): string {
    return this._import_type;
  }
  public set import_type(value: string) {
    this._import_type = value;
  }
  
  private _settings: Array<string> = [];
  public get settings(): Array<string> {
    return this._settings;
  }
  public set settings(value: Array<string>) {
    this._settings = value;
  }
  
  private _last_import_time: string;
  public get last_import_time(): string {
    return this._last_import_time;
  }
  public set last_import_time(value: string) {
    this._last_import_time = value;
    if (this._last_import_time) {
      this._lastImportTimeDate = DateTools.isoFix(this._last_import_time);
    }
  }

  private _lastImportTimeDate: Date;
  public get lastImportTimeDate(): Date {
    return this._lastImportTimeDate;
  }
  

  /***********************************************/
  /* /external/import/ attributes */
  /***********************************************/
  private _auto_add_cars: boolean = true;
  public get auto_add_cars(): boolean {
    return this._auto_add_cars;
  }
  public set auto_add_cars(value: boolean) {
    this._auto_add_cars = value;
  }

  private _auto_add_drivers: boolean = true;
  public get auto_add_drivers(): boolean {
    return this._auto_add_drivers;
  }
  public set auto_add_drivers(value: boolean) {
    this._auto_add_drivers = value;
  }

  private _comp_id: string;
  public get comp_id(): string {
    return this._comp_id;
  }
  public set comp_id(value: string) {
    this._comp_id = value;
  }

  private _comp_name: string;
  public get comp_name(): string {
    return this._comp_name;
  }
  public set comp_name(value: string) {
    this._comp_name = value;
  }

  private _last_error: string;
  public get last_error(): string {
    return this._last_error;
  }
  public set last_error(value: string) {
    this._last_error = value;
  }

  private _auth_hashkey: string;
  public get auth_hashkey(): string {
    return this._auth_hashkey;
  }
  public set auth_hashkey(value: string) {
    this._auth_hashkey = value;
  }

  private _pwd: string;
  public get pwd(): string {
    return this._pwd;
  }
  public set pwd(value: string) {
    this._pwd = value;
  }

  private _usr: string;
  public get usr(): string {
    return this._usr;
  }
  public set usr(value: string) {
    this._usr = value;
  }

  get apiObject(): any {
    return {
      auto_add_drivers: this.auto_add_drivers,
      auto_add_cars: this.auto_add_cars,
      auth_hashkey: this.auth_hashkey,
      import_type: this.import_type,
      comp_id: this.comp_id,
      comp_name: this.comp_name,
      pwd: this.pwd,
      server_url: this.server_url,
      usr: this.usr
    };
  }
  

  /***********************************************/
  /* /external/import/<type>/cars attributes */
  /***********************************************/
  private _car_key: number;
  public get car_key(): number {
    return this._car_key;
  }
  public set car_key(value: number) {
    this._car_key = value;
  }

  private _car: Vehicle = null;
  public get car(): Vehicle {
    return this._car;
  }
  public set car(value: Vehicle) {
    this._car = value;
  }
  
  private _ext_id: string;
  public get ext_id(): string {
    return this._ext_id;
  }
  public set ext_id(value: string) {
    this._ext_id = value;
  }
  
  private _ext_name: string;
  public get ext_name(): string {
    return this._ext_name;
  }
  public set ext_name(value: string) {
    this._ext_name = value;
  }

  private _server_url: string;
  public get server_url(): string {
    return this._server_url;
  }
  public set server_url(value: string) {
    this._server_url = value;
  }

  private _last_imported_rec_time: string;
  public get last_imported_rec_time(): string {
    return this._last_imported_rec_time;
  }
  public set last_imported_rec_time(value: string) {
    this._last_imported_rec_time = value;
    if (this._last_imported_rec_time) {
      this._lastRecTimeDate = DateTools.isoFix(this._last_imported_rec_time);
    }
  }

  private _lastRecTimeDate: Date;
  public get lastRecTimeDate(): Date {
    return this._lastRecTimeDate;
  }

  // custom attribute for last_import_time in import_service table
  private _last_service_import_time: string;
  public get last_service_import_time(): string {
    return this._last_service_import_time;
  }
  public set last_service_import_time(value: string) {
    this._last_service_import_time = value;
    if (this._last_service_import_time) {
      this._lastServiceTimeDate = DateTools.isoFix(this._last_service_import_time);
    }
  }
  
  private _lastServiceTimeDate: Date;
  public get lastServiceTimeDate(): Date {
    return this._lastServiceTimeDate;
  }

  // custom fms status
  public readonly FMS_OK: string = 'FMS_OK';
  public readonly FMS_NO_CONNECTION: string = 'FMS_NO_CONNECTION';
  public readonly FMS_ERROR: string = 'FMS_ERROR';

  private _fmsStatus: string = null;
  public get fmsStatus(): string {
    return this._fmsStatus;
  }
  public set fmsStatus(value: string) {
    this._fmsStatus = value;
  }

  setFmsStatus(): void {
    if (this._lastRecTimeDate && this._lastImportTimeDate && !this.isImportOlderMinutes(20)) {
      if (!this.isRecOlderMinutes(33)) {
        this._fmsStatus = this.FMS_OK;
      }
      else if (this.isRecOlderMinutes(33)) {
        this._fmsStatus = this.FMS_NO_CONNECTION;
      }
    }
    else if (!this._lastRecTimeDate || !this._lastImportTimeDate || this.isImportOlderMinutes(20)) {
      this._fmsStatus = this.FMS_ERROR;
    }
  }

  // these getters have caused ExpressionChangedAfterItHasBeenCheckedError
  get fmsOk(): boolean {
    return (
      this._lastRecTimeDate && this._lastImportTimeDate && // this._lastServiceTimeDate && 
      !this.isRecOlderMinutes(33) && !this.isImportOlderMinutes(33) // !this.isServiceOlderMinutes(20)
    );
  }

  get fmsNoConnection(): boolean {
    return (
      this._lastRecTimeDate && this._lastImportTimeDate && // this._lastServiceTimeDate && 
      this.isRecOlderMinutes(33) && !this.isImportOlderMinutes(33) // !this.isServiceOlderMinutes(20)
    );
  }

  get fmsError(): boolean {
    return (
      !this._lastRecTimeDate || !this._lastImportTimeDate || this.isImportOlderMinutes(33)
      // !this._lastRecTimeDate || !this._lastServiceTimeDate || this.isServiceOlderMinutes(20)
    );
  }
  
  isRecOlderMinutes(minutes: number): boolean {
    if (!this._lastRecTimeDate) {
      return false;
    }
    let ms = minutes * 60 * 1000;
    return ((Date.now() - ms) > this._lastRecTimeDate.getTime());
  }
  
  isImportOlderMinutes(minutes: number): boolean {
    if (!this._lastImportTimeDate) {
      return false;
    }
    let ms = minutes * 60 * 1000;
    return ((Date.now() - ms) > this._lastImportTimeDate.getTime());
  }

  isServiceOlderMinutes(minutes: number): boolean {
    if (!this._lastServiceTimeDate) {
      return false;
    }
    let ms = minutes * 60 * 1000;
    return ((Date.now() - ms) > this._lastServiceTimeDate.getTime());
  }
}