import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Title } from '@angular/platform-browser';
import { Observable, Subscription } from "rxjs";

import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";
import { MessageService } from "../../service/message.service";
import { ObligationService } from "src/app/service/obligation.service";
import { Message } from "../../model/message.object";

@Component({
  selector: 'message-history',
  templateUrl: './r-messages-history.component.html',
  styleUrls: ['./r-messages-history.component.css'],
  providers: [
    ObligationService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RMessagesHistoryComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];

  private readonly _TODAY: Date = new Date();

  public selectedDate: string = null;
  private _dateFrom: Date = null;
  public get dateFrom(): Date {
    return this._dateFrom;
  }
  private _dateTo: Date = null;
  public get dateTo(): Date {
    return this._dateTo;
  }
  
  private _messages: Array<Message> = [];
  get messages(): Array<Message> {
    return this._messages;
  }

  private _broadcastVisible: boolean = false;
  get broadcastVisible(): boolean {
    return this._broadcastVisible;
  }

  private _disableScrollAutoload: boolean = false;

  constructor(
    private _messageService: MessageService,
    private _layout: TruckManagerLayoutService,
    private _datePipe: DatePipe,
    private _cdr: ChangeDetectorRef,
    private _title: Title
  ) {
    this.selectedDate = this._datePipe.transform(this._TODAY, 'yyyy-MM-dd');
    this._dateFrom = new Date(this._TODAY.getTime());
    this._dateFrom.setHours(1, 0, 0);
    this._dateTo = this._TODAY;
    this._dateTo.setHours(23,59,59);
    
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 5000
    );
  }

  ngOnInit(): void {
    this._title.setTitle($localize`TM komunikátor`);
    // this.layout.isDashboardFullMode = true;

    // hotfix via window timeout - authentication in new tab solution 
    // (little time needed when sending/receving token between session and local storage)
    // this._loadingTimeout = true;
    window.setTimeout(
      () => {
        // load todays messages on init
        this.loadMessages();

        this._subscribed.push(
          this._messageService.getNewMessages(false).subscribe(
            response => {
              if (response && response.length) {
                let messages: Array<Message> = [];
                response.forEach(
                  message => {
                    // possible entry of duplicate messages
                    if (!this._messages.find(m => m.msg_key == message.msg_key)) {
                      // check if message time pass curent time period
                      if (message.time >= this._dateFrom) {
                        messages.push(message);
                      }
                    }
                  }
                );
                if (messages.length) {
                  this._messages = this._messages.concat(messages);
                  this._messages.sort(
                    (message, messageB) => {
                      if (message.time > messageB.time) { return -1; }
                      else if (message.time < messageB.time) { return 1; }
                      return 0;
                    }
                  );
                  
                  // custom detection
                  this.detectChanges();
                }
              }
            }
          ),
          this._layout.scrolledToBottomEvent.subscribe(
            (scrolled: boolean) => {
              if (scrolled && !this._messageService.loadingMessagesHistory && !this._disableScrollAutoload) {
                // little timeout as hotfix for user experience multiple scrolling
                this._disableScrollAutoload = true;
                window.setTimeout(
                  () => {
                    this._disableScrollAutoload = false;
                  }, 2500
                )

                // load previous day messages
                this.loadNextMessages();
                // custom detection
                this.detectChanges();
              }
            }
          )
        );
      }, 500
    );
  }

  ngOnDestroy(): void {
    this._subscribed.forEach(
      subscription => subscription.unsubscribe()
    );
    // this.layout.isDashboardFullMode = false;
  }
  
  public detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }

  
  /**************************************************/
  /* loading messages */
  /**************************************************/
  loadNextMessages(): void {
    // load previous day
    this._dateFrom = new Date(this._dateFrom.getTime() - (24*60*60*1000));
    // this.loadMessages();
    this.getMessages().subscribe();
    // custom detection
    this.detectChanges();
  }

  loadMessages(): void {
    this._subscribed.push(
      this.getMessages().subscribe(
        (allByDayMessages: any) => {
          let messages = [];
          let dayString = this._datePipe.transform(this._dateFrom, 'dd.MM.yyyy');
          console.log(allByDayMessages);
          console.log(this._dateFrom);
          console.log(dayString);
          console.log(allByDayMessages[dayString]);
          if (allByDayMessages[dayString]) {
            allByDayMessages[dayString].forEach(
              message => {
                // possible entry of duplicate messages
                if (!this._messages.find(m => m.msg_key == message.msg_key)) {
                  messages.push(message);
                }
              }
            );

            if (messages.length) {
              this._messages = this._messages.concat(messages);
              this._messages.sort(
                (message, messageB) => {
                  if (message.time > messageB.time) {
                    return -1;
                  }
                  if (message.time < messageB.time) {
                    return 1;
                  }
                  return 0;
                }
              );
            }
            console.log(this._messages);
          }
          // custom detection
          this.detectChanges();
        }
      )
    );
  }

  private getMessages(): Observable<any> {
    if (this._dateFrom) {
      let selected_date: Date = this._TODAY;
      if (this.selectedDate) {
        selected_date = new Date(this.selectedDate);
      }

      // offset in days (from today)
      let offset: number = 0;
      offset = selected_date.getTime() - this._dateFrom.getTime();
      offset = Math.floor(offset / (24 * 60 * 60 * 1000));
      // should be pastward
      if (offset) {
        offset = (-1) * offset;
      }

      // custom detection
      this.detectChanges();

      console.log('getMessages');

      // load always just one particular day
      return this._messageService.getMessagesHistory(offset, 0, selected_date);
    }
  }


  /**************************************************/
  /* getters */
  /**************************************************/
  get loading(): boolean {
    return this._messageService.loadingMessagesHistory;
  }


  /**************************************************/
  /* selecting day */
  /**************************************************/
  searchMessagesDate(): void {
    this._messages = [];
    if (this.selectedDate) {
      this._dateFrom = new Date(this.selectedDate);
      this._dateFrom.setHours(0, 0, 0);
      this._dateTo = new Date(this.selectedDate);
      this._dateTo.setHours(23, 59, 59);

      this._messageService.cleanCache();
      this.loadMessages();
      // custom detection
      this.detectChanges();
    }
  }
  

  /**************************************************/
  /* broadcast message modal  */
  /**************************************************/
  showBroadcast() {
    this._broadcastVisible = true;
    // custom detection
    this.detectChanges();
  }

  hideBroadcast() {
    this._broadcastVisible = false;
    // custom detection
    this.detectChanges();
  }


  
  
  // OBSOLETE
  // private _daysLength = 7;
  // private _totalOffset = -1;
  // private from: Date;
  // private to: Date;

  // private readonly _PAGE_SIZE: number = 20;
  // private _pageNumber: number = 1;
  // private _newPageDisplaying: boolean = false;
  // public get newPageDisplaying(): boolean {
  //   return this._newPageDisplaying;
  // }

  // private _messagesTruncated: Array<Message> = [];
  // public get messagesTruncated(): Array<Message> {
  //   return this._messagesTruncated;
  // }
  
  // private _loadingTimeout: boolean = false;

  // moveToPast() {
  //   this._totalOffset--;
  //   this.loadHistory();
  // }

  // moveToFuture() {
  //   this._totalOffset++;
  //   this.loadHistory();
  // }
  

  // get currentRange(): string {
  //   return DateTools.formatLocaleString(this.from, '%day.%month.%year') + '|' + DateTools.formatLocaleString(this.to, '%day.%month.%year');
  // }
  
  // private setCurrentRange() {
  //   // let from = new Date();
  //   // let to = new Date();
  //   from.setDate(from.getDate() + (this._daysLength * this._totalOffset));
  //   to.setDate(to.getDate() + (this._daysLength * this._totalOffset) + this._daysLength);
  //   this._dateFrom = from;
  //   this._dateTo = to;
  // }

  // private loadHistory(): Observable<any> {
  //   this.setCurrentRange();
  //   return this._messageService.getMessagesHistory(
  //     (this._daysLength * this._totalOffset),
  //     this._daysLength
  //   )
  // }

  // showNextMessages(): void {
  //   if (this._messages.length > this._messagesTruncated.length) {
  //     this._newPageDisplaying = true;

  //     window.setTimeout(
  //       () => {
  //         this._pageNumber += 1;
  //         if (this._messages.length > this._pageNumber * this._PAGE_SIZE) {
  //           // this._messagesTruncated = this._messages.slice(0, this._pageNumber * this._PAGE_SIZE);
  //           this._messagesTruncated = this._messagesTruncated.concat(
  //             this._messages.slice(this._messagesTruncated.length, this._pageNumber * this._PAGE_SIZE)
  //           );
  //         }
  //         else {
  //           this._messagesTruncated = this._messages;
  //         }
  //         this._newPageDisplaying = false;
  //       }, 250
  //     );
  //   }
  // }
  
}