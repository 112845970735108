import { Subject } from "rxjs";

// Object that keeps common logic for pattern and obligation costs itself
export class ObligationCosts {
  private _cost_key: number;
  public get cost_key(): number {
    return this._cost_key;
  }
  public set cost_key(value: number) {
    this._cost_key = value;
  }
  
  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _obligation_key: number;
  public get obligation_key(): number {
    return this._obligation_key;
  }
  public set obligation_key(value: number) {
    this._obligation_key = value;
  }

  private _created_time: number;
  public get created_time(): number {
    return this._created_time;
  }
  public set created_time(value: number) {
    this._created_time = value;
  }
  
  private _additional_charge: number;
  public get additional_charge(): number {
    return this._additional_charge;
  }
  public set additional_charge(value: number) {
    this._additional_charge = value;
  }

  private _amount: number = 1;
  public get amount(): number {
    return this._amount;
  }
  public set amount(value: number) {
    this._amount = value;
  }

  // string or autocompleter event item
  private _name: any;
  public get name(): any {
    return this._name;
  }
  public set name(value: any) {
    this._name = value;
  }

  private _price: number;
  public get price(): number {
    return this._price;
  }
  public set price(value: number) {
    this._price = value;
    this._exchanged_price = value;
  }

  // custom
  private _exchanged_price: number;
  public get exchanged_price(): number {
    return this._exchanged_price;
  }
  public set exchanged_price(value: number) {
    this._exchanged_price = value;
  }
  
  private _currency: string;
  public get currency(): string {
    return this._currency;
  }
  public set currency(value: string) {
    this._currency = value;
  }
  
  private _vat: number;
  public get vat(): number {
    return this._vat;
  }
  public set vat(value: number) {
    this._vat = value;
  }

  private _vat_rate: number;
  public get vat_rate(): number {
    return this._vat_rate;
  }
  public set vat_rate(value: number) {
    this._vat_rate = value;
  }

  private _include_in_sales: boolean;
  public get include_in_sales(): boolean {
    return this._include_in_sales;
  }
  public set include_in_sales(value: boolean) {
    this._include_in_sales = value;
  }

  // name autocompleter
  private _nameTypeahead: any;
  public get nameTypeahead(): any {
    return this._nameTypeahead;
  }
  public set nameTypeahead(value: any) {
    this._nameTypeahead = value;
  }

  public focusSubjectName$: Subject<string> = new Subject<string>();
  public clickSubjectName$: Subject<string> = new Subject<string>();

  private _position: number = null;
  public get position(): number {
    return this._position;
  }
  public set position(value: number) {
    this._position = value;
  }

  // custom
  private _price_total: number;
  public get price_total(): number {
    return this._price_total;
  }
  public set price_total(value: number) {
    this._price_total = value;
  }

  get apiObjectCostsPattern(): any {
    return {
      additional_charge: this._additional_charge,
      name: this._name,
      price: this._price,
      currency: this._currency,
      include_in_sales: this._include_in_sales,
      vat_rate: this._vat_rate,
    }
  }

  get apiObject(): any {
    return {
      additional_charge: this._additional_charge,
      amount: this._amount,
      name: this._name,
      price: this._price,
      currency: this._currency,
      vat: this._vat,
      vat_rate: this._vat_rate,
      include_in_sales: this._include_in_sales,
      obligation_key: this._obligation_key
    }
  }
}