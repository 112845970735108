import { EventEmitter, Injectable } from "@angular/core";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";

import { HttpClientService } from "./http-client.service";
import { NotificationService } from "./notification-service";
import { AuthenticationService } from './authentication.service';
import { MessageAttachmentObject } from "../model/message-attachment.object";
import { Attachment } from '../model/attachment.object';
import { IS_DEMO, ServiceConfiguration } from "../config";
import { connections } from "../connect";

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private _convertingPdf: boolean = false;
  public get convertingPdf(): boolean {
    return this._convertingPdf;
  }

  private _convertingPdfToHtml: boolean = false;
  public get convertingPdfToHtml(): boolean {
    return this._convertingPdfToHtml;
  }

  private _searchingFiles: boolean = false;
  public get searchingFiles(): boolean {
    return this._searchingFiles;
  }

  constructor(
    private _http: HttpClientService, 
    private _notificationService: NotificationService,
    private _authService: AuthenticationService,
    private http: HttpClient
  ) {

  }

  // method for sending file to vehicle
  sendFileToVehicle(carKey: number, file: File): Observable<any> {
    let data = new FormData();
    data.append('file', file);
    return this._http.post(MessageAttachmentObject.doReplaceUrlPlaceholders(
      ServiceConfiguration.files.filesApiVehicleUpload,
      '',
      carKey.toString(),
      ''
    ), data);
  }

  /******************************************/
  // methods for management of files/company
  /******************************************/
  // method for downloading/opening file via given url
  downloadFile(url: string): Observable<Blob> {
    // "https://app2.truckmanager.eu/nettedev/api/v1/files/company/52135-Objednavka1.pdf?name=Objednavka1.pdf"
    // console.log(connections.apiUrl + url);
    return this._http.get(connections.apiUrl + url, {responseType: 'blob'});
  }

  // method for uploading company file
  public uploadCompanyFile(file: any):Observable<any> {
    let updFile: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // Normalize to separate diacritics
      let sanitized_filename = file.name.normalize("NFKD"); 
      // Remove diacritics
      sanitized_filename = sanitized_filename.replace(/\p{Diacritic}/gu, "");
      // Replace non-alphanumeric (except dots) with "_"
      sanitized_filename = sanitized_filename.replace(/[^a-zA-Z0-9.]+/g, "_"); 

      const formData: FormData = new FormData();
      formData.append('file', file, sanitized_filename);
      // formData.append('file', file);

      this._http.post(ServiceConfiguration.files.companyFiles + '?mode=overwrite' , formData).subscribe(
        response => {
          console.log(response);
          this._notificationService.alert($localize`Dokument byl úspěšně uložen`, 'success', 3500);
          updFile.next(response);
        },
        error => {
          // handle error
          console.log(error);
          this._notificationService.alert($localize`Chyba při ukládání souboru!`, 'error', 3500);
        }
      );
    }
    return updFile.asObservable();
  }

  // method for deleting file from /files/company/*
  deleteFile(file: Attachment): void {
    if (!file || !file.name || !file.url) return;
    
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // let url: string = ServiceConfiguration.files.companyFiles + file.name;
      let url: string = connections.apiUrl + file.url;
      this._http.delete(url).subscribe(
        response => {
          // console.log(response);
          let alert: string = $localize`Soubor %Name byl úspěšně odstraněn.`;
          alert = alert.replace('%Name', file.name);
          this._notificationService.alert(alert, 'success', 3500);
        },
        error => {
          console.log(error);
          // error alert
          let alert: string = $localize`Chyba při odstranění souboru %Name.`;
          alert = alert.replace('%Name', file.name);
          this._notificationService.alert(alert, 'error', 3500);
        }
      );
    }
  }

  
  /******************************************/
  // methods for converting files/pdf
  /******************************************/
  // POST /files/topdf - this method returns pdf as blob
  convertToPdfDefault(file: File): Observable<HttpResponse<Blob>> {
    let pdfResponse = new EventEmitter<HttpResponse<Blob>>();
    let formData = new FormData();
    formData.append('file', file);
    this._http.map = false;
    let options: any = {
      headers: new HttpHeaders(),
      observe: 'response',
      responseType: 'blob' as 'json'
    };

    this._convertingPdf = true;

    this._http.post(ServiceConfiguration.files.filesPdfConvertor, formData, options).subscribe(
      response => {
        // console.log(response);
        this._convertingPdf = false;
        pdfResponse.emit(response);
      },
      error => {
        console.log(error);
        this._convertingPdf = false;
        this._notificationService.alert(
          $localize`Chyba při převodu souboru na formát PDF.`, 'error', 3000
        );
      }
    );
    return pdfResponse.asObservable();
  }

  // POST /files/topdf?format=base64 this method returns base64 encoding - obsolete
  convertToPdfBase64(file: File): Observable<HttpResponse<string>> {
    let pdfResponse = new EventEmitter<HttpResponse<string>>();
    let data = new FormData();
    data.append('file', file);
    this._http.map = false;
    let options = {
      headers: new HttpHeaders(),
      responseType : 'text'
    };

    this._convertingPdf = true;

    this._http.post(ServiceConfiguration.files.filesPdfConvertor + '?format=base64', data, options).subscribe(
      (response: HttpResponse<string>) => {
        this._convertingPdf = false;
        pdfResponse.emit(response);
      },
      (error) => {
        this._convertingPdf = false;
        this._notificationService.alert('Chyba', 'error');
      }
    );
    return pdfResponse.asObservable();
  }

  createBase64ForAttachment(attachment: Attachment): void {
    if (attachment && attachment.thumbnail.blob) {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(attachment.thumbnail.blob);
      reader.onload = (event: any) => {
        // console.log('Image in Base64: ', event.target.result);
        attachment.thumbnailBase64 = event.target.result;
      };
      reader.onerror = (event: any) => {
        console.log("File could not be read: " + event.target.error.code);
      };
    }
  }

  createBase64ForLocalImg(path: string): Observable<string> {
    let result: BehaviorSubject<string> = new BehaviorSubject(null);

    this.http.get(path, { responseType: 'blob' }).subscribe(
      blob => {
        const reader = new FileReader();
        const binaryString = reader.readAsDataURL(blob);
        reader.onload = (event: any) => {
          result.next(event.target.result);
          // console.log('Image in Base64: ', event.target.result);
        };
        reader.onerror = (event: any) => {
          result.next(null);
          console.log("File could not be read: " + event.target.error.code);
        };
      },
      error => {
        console.log(error);
      }
    );

    return result.asObservable();
  }

  // POST /files/pdftohtml
  convertPdfToHtml(file: File): Observable<any> {
    let htmlResponse = new EventEmitter<any>();
    let data = new FormData();
    data.append('file', file);
    this._http.map = false;
    let options = {
      headers: new HttpHeaders(),
      responseType: 'text'
    };

    this._convertingPdfToHtml = true;

    this._http.post(ServiceConfiguration.files.filesPdfToHtml, data, options).subscribe(
      (response: HttpResponse<string>) => {
        this._convertingPdfToHtml = false;
        // console.log(response);
        htmlResponse.emit(response);
      },
      error => {
        this._convertingPdfToHtml = false;
        this._notificationService.alert('Chyba', 'error');
      }
    );

    return htmlResponse.asObservable();
  }


  /******************************************/
  // methods for searching files
  /******************************************/
  // method to searching for specific files
  public searchSpecificCarFile(filename: string): Observable<Array<Attachment>> {
    let files: BehaviorSubject<Array<Attachment>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._searchingFiles = true;

      this._http.get(ServiceConfiguration.files.filesSearch + 'from?name=' + filename)
        .subscribe(
          response => {
            // console.log(response);
            let arr: Array<Attachment> = this.buildAttachments(response);
            files.next(arr);
          },
          error => {
            // handle error
            console.log(error);
            this._searchingFiles = false;
          },
          () => {
            this._searchingFiles = false;
          }
        );
    }
    return files.asObservable();
  }


  /******************************************/
  // methods for driver files managing
  /******************************************/
  // Methods for company driver documents api - /files/company/dk<driver_key>
  downloadDriverDocuments(driver_key: number): Observable<Array<Attachment>> {
    let result: BehaviorSubject<Array<Attachment>> = new BehaviorSubject([]);
    
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.files.companyFiles + 'dk' + driver_key + '/';
      this._http.get(url).subscribe(
        response => {
          let arr: Array<Attachment> = this.buildAttachments(response);
          result.next(arr);
        },
        error => {
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }
  
  // method for updating trailer properties
  public uploadDriverDocument(driver_key: number, file: any):Observable<any> {
    let updFile: BehaviorSubject<any> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // Normalize to separate diacritics
      let sanitized_filename = file.name.normalize("NFKD"); 
      // Remove diacritics
      sanitized_filename = sanitized_filename.replace(/\p{Diacritic}/gu, "");
      // Replace non-alphanumeric (except dots) with "_"
      sanitized_filename = sanitized_filename.replace(/[^a-zA-Z0-9.]+/g, "_"); 

      // initialize data to upload
      const formData: FormData = new FormData();
      formData.append('file', file, sanitized_filename);
      // formData.append('file', file);
      
      let url: string = ServiceConfiguration.files.companyFiles + 'dk' + driver_key + '/?mode=overwrite';
      this._http.post(url, formData)
        .subscribe(
          response => {
            console.log(response);
            this._notificationService.alert(
              $localize`Dokument byl úspěšně uložen`, 'success', 3500
            );
            updFile.next(response);
          },
          error => {
            // handle error
            console.log(error);
            this._notificationService.alert(
              $localize`Chyba při ukládání souboru!`, 'error', 3500
            );
          }
        );
    }
    return updFile.asObservable();
  }

  
  // method for downloading/opening file from given url
  getDocument(url: string): Observable<Blob> {
    // "https://app2.truckmanager.eu/nettedev/api/v1/files/company/dk87/test.pdf
    return this._http.get(connections.apiUrl + encodeURI(url), {responseType: 'blob'});
  }

  
  private _downloadingFile: boolean = false;
  public get downloadingFile(): boolean {
    return this._downloadingFile;
  }
  
  // method for downloading selected driver document
  openAttachmentNewTab(attachment: Attachment): void {
    // set downloading flag
    this._downloadingFile = true;
    attachment.downloadingAttachment = true;

    // getting attachments and files could be done with this method - its same
    this.getDocument(attachment.url).subscribe(
      x => {
        var newBlob = new Blob([x], { type: x.type ? x.type : "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && (navigator as any).msSaveOrOpenBlob) {
          (navigator as any).msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        // var link = document.createElement('a');
        // link.href = data;
        // link.download = attachment.name;
        // // this is necessary as link.click() does not work on the latest firefox
        // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        // unset downloading flag
        this._downloadingFile = false;

        // open new tab (does not work when ad block is activated)
        let w = window.open(data, '_blank');
        if (w) {
          setTimeout(() => w.document.title = attachment.name, 200);
        }

        // setTimeout(function () {
        //     // For Firefox it is necessary to delay revoking the ObjectURL
        //     window.URL.revokeObjectURL(data);
        //     link.remove();
        // }, 100);
      }
    );
  }


  
  /******************************************/
  // method for building attachments object array
  buildAttachments(data: Array<any>): Array<Attachment> {
    if (!data) return [];

    let attachmentArr = new Array<Attachment>();
    data.forEach(
      at => {
        let attachment = new Attachment();
        for (let key in at) {
          attachment[key] = at[key];
        }
        attachmentArr.push(attachment);
      }
    );
    return attachmentArr;
  }
}